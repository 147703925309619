<template>
  <UiPopup v-model="modelValue" size="small" title="Lead interests">
    <div class="flex flex-row flex-wrap items-center gap-x-1 gap-y-2">
      <UiTagSecondary
        v-for="(tag, index) in tags"
        :id="`tag_${index}`"
        :key="index"
        :show-tooltip="!!tag.tooltip"
        height-class="h-[22px]"
      >
        <template #content>
          <span class="text-[10px] font-medium">
            {{ tag.text }}
          </span>
        </template>
        <template #tooltip>
          {{ tag.tooltip }}
        </template>
      </UiTagSecondary>
    </div>
  </UiPopup>
</template>

<script setup lang="ts">
import type { Lead } from '@/types'

const modelValue = defineModel<boolean>({ required: true })

type Props = {
  lead: Lead
}

const props = defineProps<Props>()

const tags = computedAsync(async () => await useParseInterests(props.lead), [])
</script>

<style scoped></style>
